// @flow
import { createGlobalStyle } from "styled-components";
import { normalize } from "@nested/brand/src/normalize";
import { theme } from "@nested/brand/src/theme";
import { fonts } from "@nested/brand/src/fonts";

export const GlobalStyles = createGlobalStyle`
    ${normalize}
    ${fonts}

    ;

    * {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        outline: none;
        font-family: ${theme.fonts.euclid};
    }

    body {
        color: ${theme.palette.hague};
        background-color: white
    }

    a {
        color: inherit;
    }
`;
