// @flow
import { Route, Switch, Redirect } from "react-router-dom";

import { GlobalStyles } from "./components/GlobalStyles";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { PublicRoute } from "./components/PublicRoute/PublicRoute";

import { AppLandingPage } from "./pages/AppLandingPage/AppLandingPage";
import { BookACall } from "./pages/BookACall/BookACall";
import { BuyingGuides } from "./pages/BuyingGuides/BuyingGuides";
import { Enquiries } from "./pages/Enquiries/Enquiries";
import { ErrorCallback } from "./pages/LoginCallback/ErrorCallback";
import { Interest } from "./pages/Interest/Interest";
import { Listing } from "./pages/Listing/Listing";
import { NotFound } from "./pages/NotFound/NotFound";
import { Offers } from "./pages/Offers/Offers";
import { PropertyAnalysis } from "./pages/PropertyAnalysis/PropertyAnalysis";
import { SaleActivity } from "./pages/SaleActivity/SaleActivity";
import { SaleStatus } from "./pages/SaleStatus/SaleStatus";
import { Settings } from "./pages/Settings/Settings";
import { Strategy } from "./pages/Strategy/Strategy";
import { ThankYou } from "./pages/ThankYou/ThankYou";
import { ViewingAvailability } from "./pages/ViewingAvailability/ViewingAvailability";
import { ViewingCalendar } from "./pages/ViewingCalendar/ViewingCalendar";
import { ViewingFeedback } from "./pages/ViewingFeedback/ViewingFeedback";
import { Viewings } from "./pages/Viewings/Viewings";
import { ErrorPage } from "./pages/ErrorPage";

const LegacySaleRedirect = ({ location }) => {
  if (location.pathname === "/sale") return <Redirect to="/sale/status" />;

  // We renamed the "Sale" section to the "Listing" section, so redirect all
  // unknown routes to the new one as people may still have old links
  // bookmarked
  const parts = location.pathname.split("/");
  const restOfPath = parts.splice(2).join("/");
  return <Redirect to={`/listing/${restOfPath}${location.search}`} />;
};

export const Routes = () => (
  <>
    <GlobalStyles />
    <Switch>
      <Route component={ErrorPage} />
      {/* Sale routes */}
      <PrivateRoute path="/sale/activity" exact component={SaleActivity} />
      <PrivateRoute path="/sale/status" exact component={SaleStatus} />
      <PrivateRoute path="/sale" component={LegacySaleRedirect} />

      {/* Listing routes */}
      <PrivateRoute path="/listing" exact component={Listing} />
      <PrivateRoute path="/listing/offers" component={Offers} />
      <PrivateRoute path="/listing/viewings" exact component={Viewings} />
      <PrivateRoute
        path="/listing/viewings/viewing-feedback/:filter?"
        exact
        component={ViewingFeedback}
      />
      <PrivateRoute
        path="/listing/viewings/calendar"
        exact
        component={ViewingCalendar}
      />
      <PrivateRoute
        path="/listing/viewings/availability"
        exact
        component={ViewingAvailability}
      />
      <PrivateRoute path="/listing/interest/enquiries" component={Enquiries} />
      <PrivateRoute path="/listing/interest" component={Interest} />
      <PrivateRoute
        exact
        path="/listing/strategy/property-analysis"
        component={PropertyAnalysis}
      />
      <PrivateRoute path="/listing/strategy" component={Strategy} />
      <PrivateRoute path="/listing/guides" component={BuyingGuides} />

      {/* Settings routes */}
      <PrivateRoute path="/settings/account-details" component={Settings} />
      {/* Misc routes */}
      <PrivateRoute path="/" exact component={Listing} />
      <PrivateRoute path="/book-a-call" component={BookACall} />
      <Route path="/callback/error" component={ErrorCallback} />
      <PublicRoute path="/thank-you" component={ThankYou} />
      {/* Redirects */}
      <Route exact path="/app" component={AppLandingPage} />

      <Redirect from="/listing/interest/performance" to="/listing/interest" />
      <Redirect exact from="/settings" to="/settings/account-details" />
      <Redirect
        exact
        from="/listing/valuation/property-analysis/:externalDealId"
        to="/listing/strategy/property-analysis/:externalDealId"
      />
      <Redirect
        exact
        from="/listing/valuation/property-analysis"
        to="/listing/strategy/property-analysis"
      />
      <Redirect exact from="/listing/valuation" to="/listing/strategy" />
      <Redirect from="/listing/market" to="/listing" />
      <Redirect exact from="/listing/buyers" to="/listing/viewings" />
      <Redirect
        exact
        from="/listing/buyers/viewing-feedback"
        to="/listing/viewings/viewing-feedback"
      />
      <Redirect exact from="/callback/logout" to="/" />
      <PublicRoute component={NotFound} />
    </Switch>
    {/* This is required for our tooltips to be able to create an invisible
        area that allows to dismiss a tooltip by clicking anywhere */}
    <div id="tooltip-portal" />
  </>
);
